.App {

  display:flex;
  margin-left:10%;
  justify-content: left;
}

a {
  text-decoration: none; /* Quita el subrayado */
  color: #ffffff;
}

a:hover {
  text-decoration: underline; /* Añade subrayado cuando el mouse está encima */
}

.button {
  display: inline-block;
  padding: 0.4rem 0.8rem;
  background-color: #3498db; /* Color de fondo del botón */
  color: #fff; /* Color del texto del botón */
  text-decoration: none;
  border-radius: 1rem; /* Bordes redondeados */
  border: none; /* Quita el borde */
  transition: background-color 0.3s; /* Efecto de transición para el cambio de color */
}

.button:hover {
  background-color: #2980b9; /* Cambia el color de fondo al pasar el mouse */
}

body {
  font-family: 'Myriad Pro', sans-serif;
  background-color: #08153a; /* Fondo azul */
  text-align: center;
}

.logo {
  text-align: left;
  padding: 1%;
  margin:1%;
  margin-left:3.5%;
  margin-bottom: 0.25rem
}

.leyenda {
  font-size: 3rem;
  color: white;
  text-align: left;
  margin-left: 9%;
  width: 110%


}

.contacto {
  margin-top: 0.5%;
  text-align: left;
  padding: 5%;
  margin-left: 5%;
}


.izquierda {
  text-align: left;
  padding: 5%;
  /* margin-left:1rem; */
}

.blanco {
  color:rgb(255, 255, 255);
}

.blanco-ofi {
  color:rgb(255, 255, 255);
  margin-left: 10%;
  margin-bottom: 40%;
}

.blanco-dire {
  color:rgb(255, 255, 255);
  margin-left: 10%;
  margin-bottom: 7%;
}

.azul {
  color:rgb(80, 103, 156)
}



.derecha {

  text-align: left;
  padding: 5%;
  /* flex-shrink: 0; */
}


.sin-puntos {
  list-style-type: none;
}


.contenedor {
  display: flex;
  justify-content: space-between;
}


@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-from-bottom {
  display: flex;
  justify-content: space-between;
  animation: slideInFromBottom 0.5s ease-out;
}


table {
  margin-left:5%;
  border-collapse: collapse;
  width: 100%;
  padding: 10rem;
  width: 80%;
  margin-top: 4%;
  
}
th {
  text-align: right;
  padding-bottom: 3%;
  font-size: 80%;
}
td {
  text-align: right;
  font-size: 80%;
}
